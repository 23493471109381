.centered {
  height: 100vh; /* Magic here */
  display: flex;
  justify-content: center;
  align-items: center;
}

.login-box {
  background-color: rgba(24, 30, 53, 0.8);
  border-radius: 6px;
  /* box-shadow: 0 0 5px;*/
  border: 1px solid #2384FF;
  padding: 40px 48px 48px 48px !important;
  min-width: 456px !important;
  min-height: 352px !important;
}

.login-box:first-child {
  color: white;
  font-weight: 500;
  font-size: 28px;
  letter-spacing: 2px;
}

.login-input {
  margin: 20px 0px 0px 0px !important;
  border-width: 0px !important;
  border-radius: 2px !important;
  font-size: 14px !important;
  height: 40px !important;
  padding: 16px 9px !important;
}

.login-box > button {
  margin-top: 60px !important;
  border-radius: 2px;
  width: 100% !important;
  height: 40px !important;
  font-size: 16px;
  font-weight: 500;
  color: #FFFFFF;
  line-height: 24px;
  letter-spacing: 1px;
}

.login-small {
  color: red !important;
  font-weight: thin;
  font-size: 14px;
  float: left !important;
}
