.wrapper {
  /* display: grid; */
  gap: 0px;
  background-color: "#F1F1F1" !important;
  grid-template-areas: "header" "nav" "content";
}

.main-head {
  grid-area: header;
  height: 5vh;
}

.main-nav {
  grid-area: nav;
  background-color: white;
  max-width: 256px !important;
  padding-top: 5px;
    min-width: 12% !important;
    height: 95%;
    bottom: 0;
    position: fixed;
}

.article {
  grid-area: article;
  margin: 24px 24px 0px 24px !important;
  padding: 0px;
  background-color: #F1F1F1;
}

/*
.side {
  grid-area: sidebar;
}
.ad {
  grid-area: ad;
}
.main-footer {
  grid-area: footer;
}
*/

/* 宽度小于 1200 像素起作用 */
@media screen and (max-width: 1200px) {
  .main-nav{
    display: none;
  }
}
/* @media (min-width: 500px) { */
@media (min-width: 1200px) {
  .wrapper {
    grid-template-columns: minmax(0, 1fr) 7fr;
    /* grid-template-columns: 190px 7fr; */
    display: grid;
    grid-template-rows: 1fr 19fr;
    grid-template-areas:
      "header  header"
      "nav     article"
  }

  nav ul {
    display: flex;
    justify-content: space-between;
  }
}

.nav-item {
  font-size: 14px;
  padding: 9px 16px 9px;
  text-align: left;
  cursor: pointer;
  /* height: 40px; = 9 + 22(font-size = 14px) + 9 */
  margin: 5px 0px;
}

.nav-item-selected {
  color: #1890FF;
  font-weight: 500;
  background-color: rgba(24, 144, 255, 0.08);
  border-right: 2px solid #1890FF;
}

.nav-icon {
  width: 16px !important;
  height: 16px !important;
  margin-right: 8px;
  margin-bottom: 2px;
}

/*@media only screen and (max-width: 1000px) {*/
/*  .main-nav {*/
/*    display: none;*/
/*  }*/
/*}*/
