.pageHead {
  text-align: center;
  color: white;
  background-color: #001728;
  padding: 4px 20px 4px 10px;
  margin: 0px;
  height: 48px;
  align-items: center;
    position: fixed;
    width: 100%;
    height: 5%;
}

.user-select {
  color: white;
  font-size: 16px;
  border: 0px;
  outline: 0px;
  margin: 0px 0px 0px 0px !important;
  background: transparent;
  background-color: #1D1D1D;
  -webkit-appearance: none;
  padding: 0px 0px 0px 8px !important;
}

.user-select:focus {
  border: solid 1px #1D1D1D !important;
}

.user-select option {
  color: black;
  background-color: white; /* #383C47 */
}

.modal-promot {
  width: 480px;
  height: 238px;
  margin-top: 40px;
  margin-bottom: 40px;
}

.modal-promot .modal-content .modal-header  {
  height: 44px;
  font-size: 16px;
}

.modal-promot .modal-content .modal-body {
  font-size: 14px;
  height: 142px;
  padding: 0px 0px 0px 0px !important;
}

.modal-promot .modal-content .modal-body div {
  margin-top: 60px;
  margin-bottom: 60px;
}

.modal-promot .modal-content .modal-foot {
  height: 52px
}

.dropdown {
  height: 100% !important;
  cursor: pointer;
  display: inline-block;
}

.dropdown > p {
  margin-bottom: 10px !important;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #001728;
  right: 0%;
  width: 120px;
  padding-top: 2px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
}

.dropdown-content > p {
  /*color: rgba(0, 0, 0, 0.85);*/
  color: white;
  margin-top: 5px !important;
  margin-bottom: 5px !important;
  padding: 5px;
  text-decoration: none;
  display: block;
}

.dropdown-content p:hover {
  /* background-color: #F1F1F1; */
  background-color: #A1A1A1;
}

.dropdown:hover .dropdown-content {
  display: block;
}
