.modal-w600 {
  min-width: 600px !important;
}

.modal-content {
  min-width: 480px;
}

.modal-title {
  font-size: 16px !important;
  font-weight: 500 !important;
  padding: 0px !important;
  margin-top: 5px !important;
}

.modal-header {
  letter-spacing: -0.2px;
  height: 44px;
  margin: 0px;
  padding: 6px 24px 10px 24px !important;
}

.modal-footer {
   height: 52px;
   padding: 0px 16px !important;
}

.modal-footer > button {
  font-size: 14px !important;
  width: 62px !important;
  height: 32px !important;
  font-size: 14px !important;
}

.modal-body {
  font-size: 14px !important;
  letter-spacing: -0.14px;
  line-height: 1.2em;
  /*color: #919191;*/
  max-height: 500px;
  margin: 0px auto 0px auto;
  padding: 20px 0px 10px 0px !important; /* 20 = 32 - 12, 32 - 12 - 20*/
  /* overflow-y: scroll; */
}

.modal-body > table {
  margin: 0px 20px;
}

.table-input > tbody > tr > td:first-child {
  text-align: right;
  padding: 0px 5px 0px 0px;
  height: 56px; /* 12 + 32 + 12 */
}

.modal-input {
  height: 32px;
  border-radius: 2px;
  width: 272px;
  padding-left: 8px;
  border: solid 1px #D9D9D9 !important;
}

.modal-text {
  height: 96px;
  border-radius: 2px;
  width: 272px;
  padding: 8px 8px;
  border: solid 1px #D9D9D9 !important;
}

.modal-input:focus {
  outline: none;
  border: solid 1px #1890FF !important;
}

.modal-input-fname {
  width: 342px;
}

.table-input > tbody > tr {
  border-bottom: none;
  font-size: 14px;
}

.modal-select {
  width: 272px;
  height: 32px !important;
  font-size: 14px;
  border-radius: 2px;
  border: solid 1px #D9D9D9 !important;
  background-color: white;
  padding-left: 8px;
}

.modal-select:focus {
  outline: none;
  border: solid 1px #1890FF !important;
}

.modal-tfoot > tr {
  border-bottom: none;
}

.modal-tfoot > tr > td {
  color: red;
  text-align: center;
  font-size: 12px;
  height: 20px;
}

button.close > span {
  font-size: 30px !important;
  font-weight: 300 !important;
}

.upload-field-title {
  margin-bottom: 5px;
  font-weight: 500;
}

.ant-message {
  z-index: 10001 !important;
}

.anticon > svg {
  vertical-align: -.15em !important;
}
