.btn:focus {
  outline: none !important;
  box-shadow: none !important;
}

.user-query { /* 管理页面上方的用户输入与按钮 */
  background-color: white;
  display: flex;
  gap: 30px;
  font-size: 14px;
  flex-wrap: wrap;
  border-radius: 2px;
  padding: 16px 24px;
  align-items: center;
  min-height: 60px;
}

.input-parameter > input { /* user-query 中的 1 个参数输入区域 */
  margin-top: 0px !important;
  width: 180px;
  height: 32px;
  border: solid 1px #D9D9D9 !important;
  border-radius: 4px !important;
  padding: 0px 0px 0px 12px;
}

input[type=text]:focus {
  outline: none;
  border: solid 1px #1890FF !important;
}

select:focus {
  outline: none;
  border: solid 1px #1890FF !important;
}

input::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #999999;
  opacity: 1; /* Firefox */
  font-size: 14px;
}

.btn-primary {
  border: none !important;
}

.query-button { /* 查询按钮 */
  min-width: 64px !important;
  height: 32px !important;
  margin: 0px 0px 0px 8px !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  /*padding: 2px 2px 2px 2px !important;*/
}

.button-blue {
  margin: 0px 0px 0px 8px !important;
  background-color: #1890FF !important;
}

/*.button-create {*/
/*  margin: 0px 0px 0px 8px !important;*/
/*  min-width: 82px !important;*/
/*}*/

.button-blank { /* 重置按钮 */
  margin: 0px 0px 0px 8px !important;
  color: #666 !important;
  border: solid 1px #D9D9D9 !important;
  background-color: transparent !important;
}

.button-lm0 {
  margin: 0px 0px 0px 0px !important;
}

.div-button-to-right { /* 按钮右靠齐 */
  margin-left: auto;
  margin-right: 0px;
  margin-top: 0px;
}

.span-seperator {
  color: #D9D9D9;
  font-weight: 500;  
}

.span-button {
  color: #1890FF;
  font-weight: 500;
  cursor: pointer;
  margin: 0px 4px;
}


/*
  "foot   foot"
  <footer className="main-footer"> </footer>
*/
.content-area {
  background-color: white;
  /*height: 85vh;*/
  width: 100%;
  margin: 16px 0px 0px 0px;
  padding: 16px 24px;
  /*overflow-y: scroll;*/
  border-radius: 2px;
}

.content-top { /* show-content 内容展示的头部 */
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  background-color: white;
  border-radius: 4px;
  font-size: 16px;
}

.content-top > div {
  font-weight: 500;
}

.span-edit{
  color: #2495FD;
}
.span-delete {
  color: #2495FD;
}

.page {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-size: 14px;
}

.page > input {
  color: #1890FF;
  border: solid 1px #1890FF !important;
  border-width: 0.1px !important;
  width: 24px !important;
  height: 22px;
  text-align: center;
  margin-top: 0px !important;
  padding: 1px;
}

.page select {
  height: 24px;
  margin-left: 5px;
  font-size: 14px;
  background-color: white;
  border: solid 1px #D9D9D9 !important;
}

.page select:focus {
  outline: none;
  border: solid 1px #1890FF !important;
}

.img-arrow {
  margin: 5px;
  width: 10px;
  height: 15px;
}

.select-query {
  height: 32px !important;
  font-size: 14px;
  margin: 0px 0px 0px 0px;
  width: 180px;
  border: solid 1px #D9D9D9 !important;
  border-radius: 4px !important;
  background-color: white;
  padding-left: 12px;
}

.react-datepicker__input-container > input {
  height: 32px !important;
  width: 180px !important;
  border: solid 1px #D9D9D9 !important;
  border-radius: 4px !important;
  padding-left: 12px;
}

.react-datepicker__input-container > input:focus {
  outline: none;
  border: solid 1px #1890FF !important;   
}

.table-content {
  width: 100%;
  margin: 16px 0px 16px 0px;
  font-size: 14px;
  text-align: left;
}

.table-content tr {
  border-bottom: 1px solid #E6E6E6;
  height: 54px;
}

.table-content > thead > tr > th {
  background-color: #FAFAFA;
  word-wrap: break-word;
  max-width: 200px;
  padding: 0px 6px 0px 10px;
}

.table-content > tbody > tr > td {
  word-wrap: break-word;
  max-width: 200px;
  padding: 0px 0px 0px 10px;
}

.table-content > tbody > tr > td > img {
  height: 54px;
  margin: 5px 0px 5px 0px;
}

.ant-picker-range {
  width: 240px !important;
}
